var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SubmittedMethod } from '@application/domain';
import { annualrealisationRepository } from 'annualrealisation';
import { loaderStore } from 'shared/loader';
import { teamRepository } from 'team';
import { escapeSingleQuotes } from 'utilities/formatters';
import { getPreviousYearsObjectList } from 'utilities/helpers/dates';
import { employeeRepository } from '../../employee';
export var AnnualrealisationStore = function () { return ({
    initialized: false,
    loading: false,
    count: 0,
    selectedStatuses: [],
    selectedTeams: [],
    selectedManagers: [],
    selectedYears: [],
    selectedInternalProgressStatus: [],
    selectedInternalStatusBSN: [],
    internalStatusBSN: ['link filecap verstuurd', 'BSN\'s ontvangen', 'BSN\'s gemeld'],
    internalProgressStatus: ['link naar klant gestuurd', 'realisatie ingevuld', 'vragen gesteld', 'check uitgevoerd',
        'audit uitvoeren', 'Pdf verstuurd naar klant', 'klant akkoord'],
    searchName: undefined,
    searchCity: undefined,
    query: {
        page: 0,
        pageSize: 10,
        sort: 'annualrealisation.team',
        order: 'DESC',
        filter: ''
    },
    queryStringTeams: '',
    queryStringStatuses: '',
    queryStringYears: '',
    queryStringName: '',
    queryStringConsultants: '',
    queryStringInternalProgressStatus: '',
    queryStringInternalStatusBSN: '',
    years: getPreviousYearsObjectList(5),
    statuses: [
        {
            name: 'Geen Realisatie & Geen BSN',
            queryString: "\"submittedBSNMethod\" = " + SubmittedMethod.None + " AND \"submittedRealisationMethod\" = " + SubmittedMethod.None
        },
        {
            name: 'Wel Realisatie & Geen BSN',
            queryString: "\"submittedBSNMethod\" = " + SubmittedMethod.None + " AND \"submittedRealisationMethod\" > " + SubmittedMethod.None
        },
        {
            name: 'Geen Realisatie & Wel BSN',
            queryString: "\"submittedBSNMethod\" > " + SubmittedMethod.None + " AND \"submittedRealisationMethod\" = " + SubmittedMethod.None
        },
        {
            name: 'Wel Realisatie & Wel BSN',
            queryString: "\"submittedBSNMethod\" > " + SubmittedMethod.None + " AND \"submittedRealisationMethod\" > " + SubmittedMethod.None
        }
    ],
    teamsFilters: [],
    teams: [],
    consultantsFilters: [],
    consultants: [],
    internalProgressStatusFilters: [],
    internalStatusBSNFilters: [],
    setTeams: function (teams) {
        this.teams = teams;
        this.teamsFilters = teams.map(function (t) { return ({
            team: t,
            queryString: "annualrealisation.team = '" + escapeSingleQuotes(t.name) + "'"
        }); });
    },
    setConsultants: function (employees) {
        this.consultants = employees.map(function (x) { return x.name; });
        this.consultantsFilters = this.consultants.map(function (c) { return ({
            consultant: c,
            queryString: "annualrealisation.consultant = '" + escapeSingleQuotes(c) + "'"
        }); });
    },
    setInternalProgressStatus: function (internalProgressStatus) {
        this.internalProgressStatus = internalProgressStatus;
        this.internalProgressStatusFilters = internalProgressStatus.map(function (x) { return ({
            internalProgressStatus: x,
            queryString: "annualrealisation.internalProgressStatus = '" + escapeSingleQuotes(x) + "'"
        }); });
    },
    setInternalStatusBSN: function (internalStatusBSN) {
        this.internalStatusBSN = internalStatusBSN;
        this.internalStatusBSNFilters = internalStatusBSN.map(function (x) { return ({
            internalStatusBSN: x,
            queryString: "annualrealisation.internalStatusBSN = '" + escapeSingleQuotes(x) + "'"
        }); });
    },
    setPreviousYear: function () {
        var previousYear = getPreviousYearsObjectList(1)[0];
        this.selectedYears = [previousYear.queryString];
        this.onChangePicker([previousYear.queryString], 'year');
    },
    annualrealisations: [],
    setAnnualrealisations: function (annualrealisations) {
        this.annualrealisations = annualrealisations;
    },
    generateQueryFilter: function () {
        return __awaiter(this, void 0, void 0, function () {
            var filter, totalCount, annualrealisations;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filter = '';
                        if (this.queryStringTeams.length > 0) {
                            filter = "(" + this.queryStringTeams + ")";
                        }
                        if (this.queryStringConsultants.length > 0) {
                            filter = "(" + this.queryStringConsultants + ")";
                        }
                        if (this.queryStringInternalProgressStatus.length > 0) {
                            filter = "(" + this.queryStringInternalProgressStatus + ")";
                        }
                        if (this.queryStringInternalStatusBSN.length > 0) {
                            filter = "(" + this.queryStringInternalStatusBSN + ")";
                        }
                        if (this.queryStringStatuses.length > 0) {
                            filter =
                                filter.length > 0
                                    ? filter + " AND (" + this.queryStringStatuses + ")"
                                    : (filter = "(" + this.queryStringStatuses + ")");
                        }
                        if (this.queryStringYears.length > 0) {
                            filter =
                                filter.length > 0
                                    ? filter + " AND (" + this.queryStringYears + ")"
                                    : (filter = "(" + this.queryStringYears + ")");
                        }
                        if (this.queryStringName.length > 0) {
                            filter =
                                filter.length > 0
                                    ? filter + " AND (" + this.queryStringName + ")"
                                    : "(" + this.queryStringName + ")";
                        }
                        return [4 /*yield*/, annualrealisationRepository.getFilterCount({
                                filter: filter
                            })];
                    case 1:
                        totalCount = _a.sent();
                        this.count = totalCount;
                        this.query.filter = filter;
                        this.query.page = 0;
                        return [4 /*yield*/, annualrealisationRepository.getMany(this.query)];
                    case 2:
                        annualrealisations = _a.sent();
                        this.setAnnualrealisations(annualrealisations);
                        return [2 /*return*/];
                }
            });
        });
    },
    onChangePicker: function (arrayValues, fieldName) {
        return __awaiter(this, void 0, void 0, function () {
            var queryString, index;
            return __generator(this, function (_a) {
                queryString = '';
                for (index = 0; index < arrayValues.length; index++) {
                    queryString =
                        index === 0
                            ? "" + arrayValues[index]
                            : queryString + " OR " + arrayValues[index];
                }
                switch (fieldName) {
                    case 'status':
                        this.queryStringStatuses = queryString;
                        break;
                    case 'team':
                        this.queryStringTeams = queryString;
                        break;
                    case 'year':
                        this.queryStringYears = queryString;
                        break;
                    case 'consultant':
                        this.queryStringConsultants = queryString;
                        break;
                    case 'internalStatusBSN':
                        this.queryStringInternalStatusBSN = queryString;
                        break;
                    case 'internalProgressStatus':
                        this.queryStringInternalProgressStatus = queryString;
                        break;
                    default:
                        break;
                }
                this.generateQueryFilter();
                return [2 /*return*/];
            });
        });
    },
    onChangeSearch: function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.queryStringName = "organisationData.name LIKE '%" + value + "%'";
                this.generateQueryFilter();
                return [2 /*return*/];
            });
        });
    },
    onTableChange: function (pageSize, current, sort, order) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (pageSize) {
                            this.query.pageSize = pageSize;
                        }
                        if (current) {
                            this.query.page = current - 1;
                        }
                        if (sort) {
                            this.query.sort = sort;
                            this.query.order = "" + (order ? (order.includes('asc') ? 'ASC' : 'DESC') : 'ASC');
                        }
                        else {
                            this.query.sort = 'annualrealisation.team';
                            this.query.order = 'DESC';
                        }
                        _a = this;
                        return [4 /*yield*/, annualrealisationRepository.getMany(this.query)];
                    case 1:
                        _a.annualrealisations = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
    setLoading: function (loadingState) {
        loaderStore.setLoading(loadingState);
    },
    setInitialized: function (initializedState) {
        this.initialized = initializedState;
    },
    onInitialize: function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.setLoading(true);
                        _a = this.setTeams;
                        return [4 /*yield*/, teamRepository.getAll()];
                    case 1:
                        _a.apply(this, [_d.sent()]);
                        _b = this.setConsultants;
                        return [4 /*yield*/, employeeRepository.getAll()];
                    case 2:
                        _b.apply(this, [_d.sent()]);
                        this.setInternalProgressStatus(this.internalProgressStatus);
                        this.setInternalStatusBSN(this.internalStatusBSN);
                        this.setPreviousYear();
                        return [4 /*yield*/, this.generateQueryFilter()];
                    case 3:
                        _d.sent();
                        _c = this;
                        return [4 /*yield*/, annualrealisationRepository.getCount()];
                    case 4:
                        _c.count = _d.sent();
                        this.initialized = true;
                        this.setLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    }
}); };
