var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var readProp = function (obj, prop) { return obj[prop]; };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var omitProp = function (obj, prop) { return Object.fromEntries(Object.entries(obj).filter(function (_a) {
    var key = _a[0];
    return key !== prop;
})); };
export var getMomentStringRange = function (start, end) {
    if (start === end) {
        return [start];
    }
    return __spreadArray([start], getMomentStringRange(moment(start).add(1, 'month').format('YYYY-MM'), end), true);
};
