var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Tabs } from 'antd';
import { ManagementStore } from './management.store';
import { useHistory } from 'react-router';
import { PnoEntityManagement } from './pno-entities';
import { useStore } from '../utilities';
var TabPane = Tabs.TabPane;
var Component = function () {
    var store = useStore(ManagementStore);
    var history = useHistory();
    return (_jsxs("div", { children: [_jsx("div", { children: _jsx("h2", { children: "Beheer" }, void 0) }, void 0), _jsx("div", { children: _jsx(Tabs, __assign({ activeKey: store.selectedTab, onTabClick: function (tab) {
                        store.setSelectedTab(tab);
                        history.replace("/management/?tab=" + tab);
                    } }, { children: _jsx(TabPane, __assign({ tab: "PNO-entiteiten" }, { children: _jsx(PnoEntityManagement, {}, void 0) }), "1") }), void 0) }, void 0)] }, void 0));
};
export default Component;
