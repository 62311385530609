var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Menu, Spin } from 'antd';
import { HeaderStore } from './header.store';
import { useStore } from 'utilities';
import { authStore } from 'auth';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Header } from 'antd/lib/layout/layout';
import Logo from 'resources/media/logo.png';
import styles from './header.module.scss';
import { LogoutOutlined } from '@ant-design/icons';
import { loaderStore } from 'shared/loader';
var Component = function () {
    var location = useLocation();
    var _a = useStore(HeaderStore), setLocation = _a.setLocation, selectedTab = _a.selectedTab, setSelectedTab = _a.setSelectedTab, logout = _a.logout;
    useEffect(function () {
        setLocation(location).then();
    }, [location]);
    var isSignedIn = authStore.isSignedIn;
    return (_jsxs("div", __assign({ className: styles.root }, { children: [isSignedIn && _jsx(Button, __assign({ onClick: logout }, { children: "Logout" }), void 0), _jsxs(Header, __assign({ style: { position: 'fixed', zIndex: 1, width: '100%' } }, { children: [_jsx("img", { className: styles.logo, src: Logo, alt: "Logo" }, void 0), loaderStore.loading ? _jsx("div", __assign({ className: styles.spinner }, { children: _jsx(Spin, {}, void 0) }), void 0) : _jsx("div", __assign({ className: styles.spinner }, { children: "\u00A0" }), void 0), _jsxs(Menu, __assign({ theme: "dark", mode: "horizontal", selectedKeys: [selectedTab] }, { children: [!isSignedIn && (_jsx(Menu.Item, { children: _jsx(Link, __assign({ to: "/", onClick: function () { return setSelectedTab('home'); } }, { children: "Home" }), void 0) }, "")), isSignedIn && (_jsx(Menu.Item, { children: _jsx(Link, __assign({ to: "/organisations", onClick: function () { return setSelectedTab('organisations'); } }, { children: "Organisaties" }), void 0) }, "organisations")), isSignedIn && (_jsx(Menu.Item, { children: _jsx(Link, __assign({ to: "/annualrealisations", onClick: function () { return setSelectedTab('annualrealisations'); } }, { children: "Rapportages" }), void 0) }, "annualrealisations")), isSignedIn && (_jsx(Menu.Item, { children: _jsx(Link, __assign({ to: "/management", onClick: function () { return setSelectedTab('management'); } }, { children: "Beheer" }), void 0) }, "management"))] }), void 0), isSignedIn && (_jsx(Button, { style: { position: 'absolute', right: '0', top: '1rem', marginRight: '0.5rem' }, title: "Uitloggen", type: "primary", shape: "round", size: "small", icon: _jsx(LogoutOutlined, {}, void 0), onClick: logout }, void 0))] }), void 0)] }), void 0));
};
export default Component;
