var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './record-management.module.scss';
import { Button, DatePicker, Input, Select, Modal, Checkbox, AutoComplete } from 'antd';
import { useStore } from '../../utilities';
import { RecordManagementStore } from './record-management.store';
import { loaderStore } from '../../shared/loader';
import { LoadingOutlined } from '@ant-design/icons';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import FormItem from 'antd/lib/form/FormItem';
import { InputSearchfield } from './components/input-searchfield';
import { useParams } from 'react-router-dom';
import EmailContainer from './components/email-container';
import { RealisationStore } from '../realisation/realisation.store';
var Component = function (props) {
    var _a;
    var store = useStore(RecordManagementStore);
    var _b = useState(store.managers), managers = _b[0], setManagers = _b[1];
    var _c = useState(store.employees), employees = _c[0], setEmployees = _c[1];
    var _d = useState([]), pnoEntities = _d[0], setPnoEntities = _d[1];
    var annualrealisation = props.annualrealisation, editInternalProgress = props.editInternalProgress;
    var _e = useParams(), year = _e.year, afasid = _e.afasid;
    useEffect(function () {
        setManagers(store.managers);
        setEmployees(store.employees);
    }, [store.managers, store.currentEmailTemplate.text, store.employees]);
    useEffect(function () {
        setPnoEntities(store.pnoEntities);
    }, [store.pnoEntities]);
    if (!(annualrealisation === null || annualrealisation === void 0 ? void 0 : annualrealisation.id) || loaderStore.loading === true) {
        return _jsx(_Fragment, {}, void 0);
    }
    return (_jsxs("div", __assign({ className: styles.roots }, { children: [_jsxs("div", __assign({ className: styles['table-container'] }, { children: [_jsx(Formik, __assign({ onSubmit: function (data, _a) {
                            var setSubmitting = _a.setSubmitting;
                            return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            setSubmitting(true);
                                            return [4 /*yield*/, editInternalProgress(data)];
                                        case 1:
                                            _b.sent();
                                            setSubmitting(false);
                                            return [2 /*return*/];
                                    }
                                });
                            });
                        }, initialValues: {
                            manager: annualrealisation.manager,
                            consultant: annualrealisation.consultant,
                            billingMethod: annualrealisation.billingMethod,
                            internalProgressStatus: annualrealisation.internalProgressStatus,
                            internalStatusBSN: annualrealisation.internalStatusBSN,
                            comment: annualrealisation.comment,
                            pnoEntityDataId: (_a = annualrealisation.organisationData) === null || _a === void 0 ? void 0 : _a.pnoEntityDataId
                        } }, { children: function (_a) {
                            var _b;
                            var handleSubmit = _a.handleSubmit, setFieldValue = _a.setFieldValue, values = _a.values, isSubmitting = _a.isSubmitting;
                            return (_jsxs(Form, __assign({ onSubmit: handleSubmit }, { children: [_jsxs("div", __assign({ className: styles['table-search-container'] }, { children: [_jsx(FormItem, __assign({ label: "Projectleider", labelCol: { span: 24 } }, { children: _jsx(AutoComplete, { options: managers.map(function (manager) { return ({
                                                        value: manager,
                                                        label: manager
                                                    }); }), value: values.manager, allowClear: true, onChange: function (v) { return setFieldValue('manager', v); }, placeholder: "Selecteer een projectleider...", filterOption: function (inputValue, option) {
                                                        return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                    } }, void 0) }), void 0), _jsx(FormItem, __assign({ label: "Uitvoerend consultant", labelCol: { span: 24 } }, { children: _jsx(AutoComplete, { options: employees.map(function (employee) { return ({
                                                        value: employee,
                                                        label: employee
                                                    }); }), value: values.consultant, allowClear: true, onChange: function (v) { return setFieldValue('consultant', v); }, placeholder: "Selecteer een uitvoerend consultant...", filterOption: function (inputValue, option) {
                                                        return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                    } }, void 0) }), void 0), _jsx(InputSearchfield, { label: 'Wijze van factureren', placeholder: 'Selecteer wijze van factureren...', defaultValue: values.billingMethod, onChange: function (billingMethod) { return setFieldValue('billingMethod', billingMethod); }, options: store.billingMethods }, void 0), _jsx(InputSearchfield, { label: 'Interne status realisatie', placeholder: 'Selecteer interne status realisatie...', defaultValue: values.internalProgressStatus, onChange: function (internalProgressStatus) { return setFieldValue('internalProgressStatus', internalProgressStatus); }, options: store.internalProgressStatus }, void 0), _jsx(InputSearchfield, { label: 'Interne status BSN\'s', placeholder: 'Selecteer interne status BSN\'s...', defaultValue: values.internalStatusBSN, onChange: function (internalStatusBSN) { return setFieldValue('internalStatusBSN', internalStatusBSN); }, options: store.internalStatusBSN }, void 0), _jsx("div", __assign({ className: styles['input-searchfield-container'] }, { children: _jsx(FormItem, __assign({ label: 'Pno-entiteit', labelCol: { span: 24 } }, { children: _jsx(Select, __assign({ allowClear: true, placeholder: 'Selecteer een pno-entiteit...', defaultValue: (_b = values.pnoEntityDataId) !== null && _b !== void 0 ? _b : undefined, className: styles['table-picker'], onChange: function (id) { return __awaiter(void 0, void 0, void 0, function () {
                                                            return __generator(this, function (_a) {
                                                                setFieldValue('pnoEntityDataId', id);
                                                                return [2 /*return*/];
                                                            });
                                                        }); } }, { children: pnoEntities.map(function (option) { return _jsx(Select.Option, __assign({ value: option.id }, { children: option.name }), option.id); }) }), void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: styles['input-searchfield-container'] }, { children: _jsx(FormItem, __assign({ label: "Opmerkingen", labelCol: { span: 24 } }, { children: _jsx(Input, { allowClear: true, placeholder: "Opmerkingen...", className: styles['table-picker'], defaultValue: values.comment, onChange: function (event) { return setFieldValue('comment', event.target.value); } }, void 0) }), void 0) }), void 0)] }), void 0), _jsxs(Button, __assign({ className: styles['submit-button'], disabled: isSubmitting || loaderStore.processing, type: "primary", htmlType: "submit" }, { children: [!loaderStore.processing ? _jsx("span", {}, void 0) : (_jsx(LoadingOutlined, {}, void 0)), "Opslaan"] }), void 0)] }), void 0));
                        } }), void 0), _jsx(Formik, __assign({ onSubmit: function (data, _a) {
                            var setSubmitting = _a.setSubmitting;
                            return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_b) {
                                    setSubmitting(true);
                                    store.GenerateCustomerEmailTemplate(afasid, year, data.linkExpiration.toDate());
                                    setSubmitting(false);
                                    return [2 /*return*/];
                                });
                            });
                        }, enableReinitialize: true, initialValues: {
                            emailText: store.currentEmailTemplate.text,
                            emailLanguage: store.defaultEmailLanguage,
                            linkExpiration: store.linkExpirationDate,
                            minLinkExpiration: store.linkExpirationDate,
                            maxLinkExpiration: store.maxLinkExperationDate
                        } }, { children: function (_a) {
                            var handleSubmit = _a.handleSubmit, isSubmitting = _a.isSubmitting, values = _a.values, setFieldValue = _a.setFieldValue;
                            return (_jsxs(Form, __assign({ onSubmit: handleSubmit }, { children: [_jsx("div", __assign({ className: styles['table-search-container'] }, { children: _jsx("div", __assign({ className: styles['input-searchfield-container'] }, { children: _jsx(FormItem, __assign({ label: "Link geldig tot", labelCol: { span: 24 } }, { children: _jsx(DatePicker, { disabledDate: function (d) { return !d || d.isAfter(values.maxLinkExpiration) || d.isSameOrBefore(values.minLinkExpiration); }, name: "start", value: values.linkExpiration, placeholder: "Link geldig tot", className: styles['input-searchfield-container'], defaultPickerValue: undefined, picker: "date", onChange: function (event) { return setFieldValue('linkExpiration', event); }, disabled: annualrealisation.blockInputRealisation }, void 0) }), void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: styles['table-search-container'] }, { children: _jsx(InputSearchfield, { label: 'Taal', placeholder: '', disabled: annualrealisation.blockInputRealisation, defaultValue: values.emailLanguage, options: store.getEmailTemplateLanguageCodes(), onChange: function (event) { return __awaiter(void 0, void 0, void 0, function () {
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0: return [4 /*yield*/, store.setCurrentEmailTemplateByLanguage(event)];
                                                        case 1:
                                                            _a.sent();
                                                            return [2 /*return*/];
                                                    }
                                                });
                                            }); } }, void 0) }), void 0), _jsxs(Button, __assign({ className: styles['submit-button'], disabled: isSubmitting ||
                                            loaderStore.processing ||
                                            annualrealisation.blockInputRealisation, type: "primary", htmlType: "submit" }, { children: [!loaderStore.processing ? _jsx("span", {}, void 0) : (_jsx(LoadingOutlined, {}, void 0)), " Link kopi\u00EBren"] }), void 0), _jsxs("div", __assign({ className: styles['input-container'] }, { children: ["Geblokkeerd jaar", _jsx(Checkbox, { checked: RealisationStore.annualrealisation.blockInputRealisation, onChange: function (event) {
                                                    RealisationStore.editBlockInputRealisation(event.target.checked);
                                                } }, void 0)] }), void 0), _jsxs("div", __assign({ className: styles['input-container'] }, { children: ["Geaccepteerd door klant", _jsx(Checkbox, { checked: annualrealisation.customerAccepted }, void 0)] }), void 0)] }), void 0));
                        } }), void 0)] }), void 0), _jsx(Modal, __assign({ className: styles.root, visible: store.showEmailModal, title: "Kopieer de tekst (CTRL + c)", onCancel: function () { return store.setShowEmailModal(false); }, footer: _jsx("div", {}, void 0) }, { children: _jsx(EmailContainer, { emailContent: store.emailContent }, void 0) }), void 0)] }), void 0));
};
export default Component;
