var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RealisationStore } from './realisation.store';
import { EditAnnualrealisationStatus } from 'organisations/edit-status';
import { loaderStore } from 'shared/loader';
import styles from './realisation.module.scss';
import { Button, Select, Tabs } from 'antd';
import { useParams } from 'react-router';
import { PlusCircleOutlined } from '@ant-design/icons';
import { AddProject } from 'organisations/add-project';
import AnnualrealisationInputSheet, { Mode, TableType } from 'organisations/annualrealisation-input-sheet/annualrealisation-input-sheet';
import { useEffect } from 'react';
import { AddPeriode } from 'organisations/add-periode';
import { useHistory } from 'react-router-dom';
import { ExportPdf } from 'organisations/realisation/export-pdf';
import { ConfirmModal } from 'shared/confirm-modal';
import { getSearchParams } from 'utilities/helpers/url';
import { ExportExcel } from './export-excel';
import { RecordManagement } from '../record-management';
var TabPane = Tabs.TabPane;
var Component = function () {
    var _a, _b;
    var store = RealisationStore;
    var history = useHistory();
    var Option = Select.Option;
    var _c = useParams(), year = _c.year, afasid = _c.afasid;
    useEffect(function () {
        store.setParams(afasid, (parseInt(year))).then();
        store.setHistory(history).then();
        var params = getSearchParams(history);
        var tab = params.get('tab');
        store.setSelectedTab(tab !== null && tab !== void 0 ? tab : '1');
    }, [store, history, year, afasid]);
    return store.afasid.length === 0 ? _jsx(_Fragment, {}, void 0) : (_jsxs("div", __assign({ className: styles.root }, { children: [_jsxs("div", __assign({ className: styles['header-wrapper'] }, { children: [_jsx("div", __assign({ className: styles['organisation-name'] }, { children: store.organisation.name }), void 0), _jsx("div", __assign({ className: styles['navigation-wrapper'] }, { children: _jsxs("div", __assign({ className: styles['navigation-items'] }, { children: [!store.annualrealisationExists ? _jsx(_Fragment, {}, void 0) : (_jsxs("span", { children: [_jsx(Button, __assign({ className: styles['navigation-item'], type: "primary", shape: "round", icon: _jsx(PlusCircleOutlined, {}, void 0), onClick: function () {
                                                store.setPeriodeModalVisable(true);
                                            } }, { children: "Periode toevoegen" }), void 0), _jsx(Button, __assign({ className: styles['navigation-item'], type: "primary", shape: "round", icon: _jsx(PlusCircleOutlined, {}, void 0), onClick: function () {
                                                store.setProjectModalVisable(true);
                                            } }, { children: "Project toevoegen" }), void 0), _jsx(ExportPdf, { exportName: store.organisation.name, annualrealisation: store.annualrealisation, applications: store.applications, tableData: store.tableData, bigExpensesTableData: store.bigExpensesTableData, pnoEntity: store.pnoEntity }, void 0), _jsx(ExportExcel, { visible: false, exportName: store.organisation.name }, void 0), _jsx(AddPeriode, { year: year, periodeModal: store.periodeModal, onCancel: function () { return store.setPeriodeModalVisable(false); }, addApplication: function (application) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, store.addApplication(application)];
                                                    case 1: return [2 /*return*/, _a.sent()];
                                                }
                                            }); }); }, applications: store.applications }, void 0), _jsx(AddProject, { showModal: store.projectModal, onCancel: function () { return store.setProjectModalVisable(false); }, addProject: function (project) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, store.addProject(project)];
                                                    case 1: return [2 /*return*/, _a.sent()];
                                                }
                                            }); }); }, projects: store.projects }, void 0)] }, void 0)), _jsx(Select, __assign({ className: styles['navigation-item'], defaultValue: store.year, style: { width: 120 }, onChange: function (value) { return store.setYear(Number(value)); } }, { children: store.years.map(function (element) { return (_jsx(Option, __assign({ value: element }, { children: element }), element)); }) }), void 0)] }), void 0) }), void 0)] }), void 0), !store.annualrealisationExists ? (_jsxs("div", __assign({ className: styles['no-annualrealisation-yet-content-wrapper'] }, { children: [_jsx("p", { children: "Er is voor deze entiteit nog geen mededeling aangemaakt voor deze jaargang" }, void 0), _jsx(Button, __assign({ type: "primary", shape: "round", icon: _jsx(PlusCircleOutlined, {}, void 0), onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, store.createNewAnnualrealisation()];
                                case 1: return [2 /*return*/, _a.sent()];
                            }
                        }); }); } }, { children: "Nieuwe Medeling Aanmaken voor deze Jaargang" }), void 0)] }), void 0)) : (_jsx("div", __assign({ className: styles['realisation-content-wrapper'] }, { children: _jsxs(Tabs, __assign({ activeKey: store.selectedTab, onTabClick: function (tab) {
                        store.setSelectedTab(tab);
                        history.replace("/organisations/" + afasid + "/years/" + store.year + "/realisation/?tab=" + tab);
                    } }, { children: [_jsx(TabPane, __assign({ tab: "Realisatieoverzicht" }, { children: loaderStore.loading ? 'De gegevens worden opgehaald...' : (_jsxs("div", __assign({ className: styles['realisation-form-wrapper'] }, { children: [_jsx("div", __assign({ className: styles['table-title'] }, { children: "Uren" }), void 0), _jsx(AnnualrealisationInputSheet, { applications: store.applications, type: TableType.Hours, rowsTitle: "Projecten", tableData: store.tableData, grantedTotal: store.annualrealisation.grantedHours, realisedTotal: store.annualrealisation.realisedHours, reportedTotal: store.annualrealisation.reportedHours, highlightReportedTotal: true, entitledTotal: store.annualrealisation.entitledHours, entitledTotalErrors: store.annualrealisationErrors.entitledHours, filterProject: function (pf) { return store.setProjectFilter(pf); }, filterValue: store.projectFilter, onInputChange: function (key, e, r) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, store.onInputChange(key, Number(e.target.value), r.projectId)];
                                                case 1: return [2 /*return*/, _a.sent()];
                                            }
                                        }); }); }, onDeleteProject: function (id) { return store.onDeleteProject(id); }, mode: Mode.Default }, void 0), store.annualrealisation.forfait ? (_jsx(Button, __assign({ type: "primary", className: styles['costs-expenses-forfait-button'], shape: "round", onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, store.editForfaitStatus(false)];
                                                case 1: return [2 /*return*/, _a.sent()];
                                            }
                                        }); }); } }, { children: "Activeer Kosten & Uitgaven" }), void 0)) : (_jsxs("span", { children: [_jsx(Button, __assign({ type: "primary", className: styles['costs-expenses-forfait-button'], shape: "round", onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0: return [4 /*yield*/, store.editForfaitStatus(true)];
                                                        case 1: return [2 /*return*/, _a.sent()];
                                                    }
                                                }); }); } }, { children: "Activeer Forfait" }), void 0), _jsx("div", __assign({ className: styles['table-title'] }, { children: "Kosten" }), void 0), _jsx(AnnualrealisationInputSheet, { applications: store.applications, type: TableType.Costs, rowsTitle: "Projecten", tableData: store.tableData, onInputChange: function (key, e, r) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0: return [4 /*yield*/, store.onInputChange(key, Number(e.target.value), r.projectId)];
                                                        case 1: return [2 /*return*/, _a.sent()];
                                                    }
                                                }); }); }, onDeleteProject: function (id) { return store.onDeleteProject(id); }, grantedTotal: store.annualrealisation.grantedCosts, realisedTotal: store.annualrealisation.realisedCosts, filterProject: function (pf) { return store.setProjectFilter(pf); }, filterValue: store.projectFilter, reportedTotal: store.annualrealisation.reportedCosts, mode: Mode.Default }, void 0), _jsx("div", __assign({ className: styles['table-title'] }, { children: "Uitgaven" }), void 0), _jsx(AnnualrealisationInputSheet, { applications: store.applications, type: TableType.Expenses, rowsTitle: "Projecten", tableData: store.tableData, onInputChange: function (key, e, r) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0: return [4 /*yield*/, store.onInputChange(key, Number(e.target.value), r.projectId)];
                                                        case 1: return [2 /*return*/, _a.sent()];
                                                    }
                                                }); }); }, onDeleteProject: function (id) { return store.onDeleteProject(id); }, grantedTotal: store.annualrealisation.grantedExpenses, realisedTotal: store.annualrealisation.realisedExpenses, filterProject: function (pf) { return store.setProjectFilter(pf); }, filterValue: store.projectFilter, reportedTotal: store.annualrealisation.reportedExpenses, mode: Mode.Default }, void 0), _jsx(Button, __assign({ type: "primary", className: styles['costs-expenses-forfait-button'], shape: "round", onClick: function () { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
                                                    switch (_b.label) {
                                                        case 0: return [4 /*yield*/, store.setIsBigExpensesAmountEnabled((_a = !store.annualrealisation.isBigExpensesAmountEnabled) !== null && _a !== void 0 ? _a : true)];
                                                        case 1: return [2 /*return*/, _b.sent()];
                                                    }
                                                }); }); } }, { children: store.annualrealisation.isBigExpensesAmountEnabled ? 'Uitgaven > 1 mln. verwijderen' : 'Uitgaven > 1 mln. toevoegen' }), void 0), store.annualrealisation.isBigExpensesAmountEnabled && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: styles['table-title'] }, { children: "Uitgaven > 1 mln." }), void 0), _jsx(AnnualrealisationInputSheet, { applications: store.applications, type: TableType.BigExpenses, tableData: store.bigExpensesTableData, hideTotalRows: true, onInputChange: function (key, e) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                                            switch (_a.label) {
                                                                case 0: return [4 /*yield*/, store.onInputChange(key, Number(e.target.value))];
                                                                case 1: return [2 /*return*/, _a.sent()];
                                                            }
                                                        }); }); }, mode: Mode.Default }, void 0)] }, void 0)), _jsx("div", __assign({ className: styles['table-title'] }, { children: "Totaal Kosten & Uitgaven" }), void 0), _jsx(AnnualrealisationInputSheet, { applications: store.applications, type: TableType.CostsExpenses, grantedTotal: store.annualrealisation.grantedCostsExpenses, realisedTotal: store.annualrealisation.realisedCostsExpenses, reportedTotal: store.annualrealisation.reportedCostsExpenses, entitledTotal: store.annualrealisation.entitledCostsExpenses, entitledTotalErrors: store.annualrealisationErrors.entitledCostsExpenses, additionalTotalRows: store.annualrealisation.isBigExpensesAmountEnabled ? [
                                                    { title: 'Mededeling (Kosten)', value: store.annualrealisation.reportedCosts, highlightValue: true },
                                                    { title: 'Mededeling (Uitgaven)', value: ((_a = store.annualrealisation.reportedExpenses) !== null && _a !== void 0 ? _a : 0) + ((_b = store.annualrealisation.reportedBigExpenses) !== null && _b !== void 0 ? _b : 0), highlightValue: true },
                                                    { title: 'Uitgaven > 1 mln.', value: store.annualrealisation.reportedBigExpenses, highlightValue: true }
                                                ] :
                                                    [
                                                        { title: 'Mededeling (Kosten)', value: store.annualrealisation.reportedCosts, highlightValue: true },
                                                        { title: 'Mededeling (Uitgaven)', value: store.annualrealisation.reportedExpenses, highlightValue: true }
                                                    ], mode: Mode.Default }, void 0)] }, void 0))] }), void 0)) }), "1"), _jsx(TabPane, __assign({ tab: "Mededelingstatus" }, { children: loaderStore.loading ? 'De gegevens worden opgehaald...' : (_jsx(EditAnnualrealisationStatus, { employees: store.employees, annualrealisation: store.annualrealisation, editStatus: function (v) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, store.editAnnualrealisationStatus(v)];
                                        case 1: return [2 /*return*/, _a.sent()];
                                    }
                                }); }); }, succesfullySavedStatus: store.succesfullySavedStatus }, void 0)) }), "2"), _jsx(TabPane, __assign({ tab: "Dossier beheer" }, { children: loaderStore.loading ? 'De gegevens worden opgehaald...' : (_jsx(RecordManagement, { annualrealisation: store.annualrealisation, editInternalProgress: function (v) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, store.editAnnualrealisationInternalStatusProgress(v)];
                                        case 1: return [2 /*return*/, _a.sent()];
                                    }
                                }); }); } }, void 0)) }), "3")] }), void 0) }), void 0)), _jsx(ConfirmModal, { open: store.selectedProjectId.length > 0, title: "Project verwijderen", description: "Weet je zeker dat je dit project wil verwijderen? Dit is niet alleen deze rij maar alle rijen van dit project in de tabellen uren, kosten en uitgaven met de bijbehorende realisaties", onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, store.deleteProject()];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                }); }); }, onCancel: function () { return store.setSelectedProjectId(''); }, id: store.selectedProjectId }, void 0)] }), void 0));
};
export default Component;
